import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography";

interface ConfirmationProps {
  registeredName: string;
}
const Confirmation = ({ registeredName }: ConfirmationProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Dankjewel {registeredName},
      </Typography>
      <Typography variant="body1" gutterBottom>
        We hebben je inschrijving voor de oefenavond goed ontvangen.
      </Typography>
      <Typography variant="body1">Tot binnenkort.</Typography>
    </>
  );
};

export default Confirmation;
