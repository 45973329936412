import { makeStyles } from "@material-ui/core/styles";

import db, { firestore } from "../../firebase/db";

export const EVENING_DATES: string[] = ["17/10/2020", "31/10/2020"];

export interface DancerInformation {
  firstName: string;
  lastName: string;
  email: string;
}

export interface MainFormValue {
  dancer1: DancerInformation;
  dancer2: DancerInformation;
  hasPartner: boolean;
  eveningDates: boolean[];
  message: string;
  createdAt: Date;
}

const DEFAULT_DANCER_INFORMATION: DancerInformation = {
  firstName: "",
  lastName: "",
  email: "",
};
export const DEFAULT_FORM_VALUES = {
  dancer1: DEFAULT_DANCER_INFORMATION,
  dancer2: DEFAULT_DANCER_INFORMATION,
  hasPartner: true,
  eveningDates: [false, false],
  message: "",
  createdAt: new Date(),
};

export const SPACING = 2;
export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: 0,
    marginBottom: 5,
  },
  gridItem: {
    marginTop: (-1 * theme.spacing(SPACING)) / 2,
    marginBottom: (-1 * theme.spacing(SPACING)) / 2,
  },
  separator: {
    height: "1px",
    background: "rgba(0, 0, 0, 0.23)",
    width: "100%",
  },
}));

export const saveFormValues = async (values: MainFormValue): Promise<void> => {
  if (db === null) {
    return;
  }

  await db.collection("registrations").add({
    type: "dance-evening",
    dancer1: values.dancer1,
    dancer2: values.hasPartner ? values.dancer2 : null,
    message: values.message,
    eveningDates: values.eveningDates.reduce<string[]>(
      (acc, value, index) => (value ? [...acc, EVENING_DATES[index]] : acc),
      []
    ),
    createdAt: firestore.Timestamp.fromDate(new Date()),
    rejectedAt: null,
  });
};
