import React from "react";
import Grid from "@material-ui/core/Grid";

import TextField from "../TextField";

import {
  MainFormValue, // eslint-disable-line  no-unused-vars
  useStyles,
} from "./constants";

interface ContactFormProps {
  prefix: "dancer1" | "dancer2";
}

const ContactForm = ({ prefix }: ContactFormProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
        <TextField
          required
          name={`${prefix}.firstName`}
          label="Voornaam"
          fullWidth
          autoComplete={`${prefix} given-name`}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6} className={classes.gridItem}>
        <TextField
          required
          name={`${prefix}.lastName`}
          label="Achternaam"
          fullWidth
          autoComplete={`${prefix} family-name`}
        />
      </Grid>
      <Grid item xs={12} className={classes.gridItem}>
        <TextField
          type="email"
          autoComplete={`${prefix} email`}
          required
          name={`${prefix}.email`}
          label="Email"
          fullWidth
        />
      </Grid>
    </>
  );
};

export default ContactForm;
